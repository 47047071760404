import { isEmpty, truncate } from 'lodash';
import moment from 'moment';
import { validateEmail } from '../../../../../validations/validation';
import {
  FormFieldNames,
  UserOnboardingTabs,
} from '../../constants/onboarding.constants';
import { isNotEmptyString } from '../onboarding.util';
import { getYearFromCurrentDate } from '../../../../../utils/formatter';
import { DateFormatters } from '../../../../../constants/dateConstants';
import {
  ComponentInputType,
  FormFieldTypes,
} from '../../../../../constants/common';
import { KRAFormFieldNames } from '../../../../Performance/Utlis/goals.util';
import { ConditionFormFieldsName } from '../../../../form-builder/utils/conditional.util';

export const UserInfoFormFields = {
  [UserOnboardingTabs.userInfo]: [
    {
      id: 1,
      fieldLabel: 'First Name',
      placeholderText: 'Type First Name here',
      fieldType: FormFieldTypes.Text,
      isRequired: true,
      fieldRefName: FormFieldNames.FirstName,
      textTransform: 'capitalize',
      copyTo: FormFieldNames.DisplayName,
    },
    {
      id: 2,
      fieldLabel: 'Last Name',
      placeholderText: 'Type Last Name here',
      fieldType: FormFieldTypes.Text,
      isRequired: false,
      fieldRefName: FormFieldNames.LastName,
      textTransform: 'capitalize',
    },
    {
      id: 3,
      fieldLabel: 'Display Name',
      placeholderText: 'Type Name here',
      fieldType: FormFieldTypes.Text,
      isRequired: true,
      fieldRefName: FormFieldNames.DisplayName,
      textTransform: 'capitalize',
    },
    {
      id: 4,
      fieldLabel: 'Date of Birth',
      placeholderText: 'dd/mm/yyyy',
      fieldType: FormFieldTypes.Date,
      isRequired: false,
      fieldRefName: 'DOB',
      maxDate: moment(),
      subitem: {
        id: 1,
        fieldLabel: 'Age',
        placeholderText: '0',
        fieldType: FormFieldTypes.Text,
        fieldRefName: 'age',
        isRequired: false,
        isDisabled: true,
        textTransform: 'none',
        calc: (val) => getYearFromCurrentDate(val),
        dataType: FormFieldTypes.Text,
      },
    },
    {
      id: 5,
      fieldLabel: 'Nationality',
      placeholderText: 'Select Nationality',
      fieldType: FormFieldTypes.SingleSelect,
      isRequired: true,
      isDisabled: false,
      fieldRefName: FormFieldNames.Nationality,
    },
    {
      id: 7,
      fieldLabel: 'Work Authorization',
      placeholderText: 'Select Authorization',
      fieldType: FormFieldTypes.SingleSelect,
      isRequired: true,
      isDisabled: false,
      fieldRefName: FormFieldNames.WorkAuthorisation,
    },
    // FIN and Singapore NRIC are common fields, Either will be accepted.
    {
      id: 8,
      fieldLabel: 'National ID number',
      placeholderText: 'Type your national id number',
      fieldType: FormFieldTypes.Text,
      isRequired: false,
      isDisabled: false,
      fieldRefName: FormFieldNames.NRICNumber,
      isHidden: true,
      textTransform: 'uppercase',
    },
    {
      id: 17,
      fieldLabel: 'FIN number',
      placeholderText: 'FIN Number',
      fieldType: FormFieldTypes.Text,
      isRequired: false,
      isDisabled: false,
      fieldRefName: FormFieldNames.FINNumber,
      isHidden: true,
      textTransform: 'uppercase',
    },
    {
      id: 9,
      fieldLabel: 'Start Date',
      placeholderText: `${DateFormatters.ddmmyy}`,
      fieldType: FormFieldTypes.Date,
      isRequired: false,
      isDisabled: false,
      fieldRefName: FormFieldNames.StartDate,
      isHidden: true,
    },
    {
      id: 10,
      fieldLabel: 'End Date',
      placeholderText: `${DateFormatters.ddmmyy}`,
      fieldType: FormFieldTypes.Date,
      isRequired: false,
      isDisabled: false,
      fieldRefName: FormFieldNames.EndDate,
      isHidden: true,
    },
    {
      id: 11,
      fieldLabel: 'Passport Number',
      placeholderText: 'eg: Z9891715',
      fieldType: FormFieldTypes.Text,
      isRequired: false,
      fieldRefName: FormFieldNames.PassportNumber,
      textTransform: 'uppercase',
    },
    {
      id: 12,
      fieldLabel: 'Passport Expiration Date',
      placeholderText: 'dd/mm/yyyy',
      fieldType: FormFieldTypes.Date,
      isRequired: false,
      fieldRefName: 'passportExpirationDate',
    },
    {
      id: 13,
      fieldLabel: 'Driving Licence Number',
      placeholderText: 'eg: T5827391045',
      fieldType: FormFieldTypes.Text,
      isRequired: false,
      fieldRefName: 'drivingLicenseNumber',
      textTransform: 'uppercase',
      isDisabled: false,
    },
    {
      id: 14,
      fieldLabel: 'Driving Licence Expiration Date',
      placeholderText: 'dd/mm/yyyy',
      fieldType: FormFieldTypes.Date,
      isRequired: false,
      fieldRefName: 'drivingLicenseExpirationDate',
    },
    {
      id: 15,
      fieldLabel: 'Added by',
      placeholderText: 'Select Name',
      fieldType: FormFieldTypes.SingleSelect,
      isRequired: false,
      isDisabled: true,
      fieldRefName: FormFieldNames.AddedBy,
    },
    {
      id: 16,
      fieldLabel: 'Added on',
      placeholderText: 'dd/mm/yyyy',
      fieldType: FormFieldTypes.Date,
      isRequired: false,
      isDisabled: true,
      fieldRefName: FormFieldNames.AddedOn,
    },
  ],
  [UserOnboardingTabs.personal]: [
    {
      id: 3,
      fieldLabel: 'Mobile number',
      placeholderText: 'Enter mobile number',
      fieldType: FormFieldTypes.Text,
      isRequired: false,
      fieldRefName: 'mobileNumber',
    },
    {
      id: 2,
      fieldLabel: 'Personal Email ID',
      placeholderText: 'eg: larak@gmail.com',
      fieldType: FormFieldTypes.Text,
      dataType: FormFieldTypes.Email,
      isRequired: true,
      fieldRefName: 'email',
    },
    {
      id: 1,
      fieldLabel: 'Gender',
      placeholderText: 'Select Gender',
      fieldType: FormFieldTypes.SingleSelect,
      isRequired: false,
      fieldRefName: FormFieldNames.Gender,
    },
    {
      id: 18,
      fieldLabel: 'Blood Group',
      placeholderText: 'Select Blood Group',
      fieldType: FormFieldTypes.SingleSelect,
      isRequired: false,
      fieldRefName: FormFieldNames.BloodGroup,
    },
    {
      id: 15,
      fieldLabel: 'Religion',
      placeholderText: 'Select Religion',
      fieldType: FormFieldTypes.SingleSelect,
      isRequired: false,
      isDisabled: false,
      fieldRefName: FormFieldNames.Religion,
    },
    {
      id: 16,
      fieldLabel: 'Race (Ethnicity)',
      placeholderText: 'Select Race (Ethnicity)',
      fieldType: FormFieldTypes.SingleSelect,
      isRequired: false,
      isDisabled: false,
      fieldRefName: FormFieldNames.Race,
    },
    {
      id: 17,
      fieldLabel: 'Add Profile icon',
      placeholderText: 'Upload in PNG, JPEG or SVG format',
      fieldType: FormFieldTypes.File,
      dataType: 'image',
      isRequired: false,
      fieldRefName: 'profileLogo',
      imageValidation: '.jpg, .jpeg, .png, .svg',
      fieldSize: 12,
    },
    {
      id: 4,
      fieldLabel: 'Emergency Contact number',
      placeholderText: 'emergency mobile number',
      fieldType: FormFieldTypes.Text,
      isRequired: true,
      fieldRefName: FormFieldNames.EmergencyMobileNumber,
    },
    {
      id: 22,
      fieldLabel: 'Relation with Emergency Contact number',
      placeholderText: 'Select Relation',
      fieldType: FormFieldTypes.SingleSelect,
      isRequired: true,
      fieldRefName: FormFieldNames.EmergencyMobileNumberRelation,
    },
    {
      id: 23,
      fieldLabel: 'Emergency Contact Name',
      placeholderText: 'Enter Name',
      fieldType: FormFieldTypes.Text,
      isRequired: true,
      fieldRefName: 'emergencyCallReceiverName',
      fieldSize: 12,
    },
    {
      id: 5,
      fieldLabel: 'Father\'s Name',
      placeholderText: 'Enter Name',
      fieldType: FormFieldTypes.Text,
      isRequired: false,
      fieldRefName: 'fatherName',
    },
    {
      id: 6,
      fieldLabel: 'Father\'s National Identity number',
      placeholderText: 'eg: Z9891715',
      fieldType: FormFieldTypes.Text,
      isRequired: false,
      fieldRefName: 'fatherId',
      textTransform: 'uppercase',
    },
    {
      id: 7,
      fieldLabel: 'Mother\'s Name',
      placeholderText: 'Enter Name',
      fieldType: FormFieldTypes.Text,
      isRequired: false,
      fieldRefName: 'motherName',
    },
    {
      id: 8,
      fieldLabel: 'Mother\'s National Identity number',
      placeholderText: 'eg: Z9891715',
      fieldType: FormFieldTypes.Text,
      isRequired: false,
      fieldRefName: 'motherId',
      textTransform: 'uppercase',
    },
    {
      id: 9,
      fieldLabel: 'Spouse Name',
      placeholderText: 'Enter Name',
      fieldType: FormFieldTypes.Text,
      isRequired: false,
      fieldRefName: 'spouseName',
    },
    {
      id: 10,
      fieldLabel: 'Spouse National Identity number',
      placeholderText: 'eg: Z9891715',
      fieldType: FormFieldTypes.Text,
      isRequired: false,
      fieldRefName: 'spouseId',
      textTransform: 'uppercase',
    },
    {
      id: 11,
      fieldLabel: '1st Child Name',
      placeholderText: 'Enter Name',
      fieldType: FormFieldTypes.Text,
      isRequired: false,
      fieldRefName: 'childName1',
    },
    {
      id: 12,
      fieldLabel: '1st Child National Identity number',
      placeholderText: 'eg: Z9891715',
      fieldType: FormFieldTypes.Text,
      isRequired: false,
      fieldRefName: 'childId1',
      textTransform: 'uppercase',
    },
    {
      id: 13,
      fieldLabel: '2nd Child Name',
      placeholderText: 'Enter Name',
      fieldType: FormFieldTypes.Text,
      isRequired: false,
      fieldRefName: 'childName2',
    },
    {
      id: 14,
      fieldLabel: '2nd Child National Identity number',
      placeholderText: 'eg: Z9891715',
      fieldType: FormFieldTypes.Text,
      isRequired: false,
      fieldRefName: 'childId2',
      textTransform: 'uppercase',
    },
    {
      id: 19,
      fieldLabel: 'Address',
      placeholderText: 'Enter complete address',
      fieldType: FormFieldTypes.Text,
      isRequired: false,
      fieldRefName: 'address',
      multiline: true,
      dataType: FormFieldTypes.MultiLineText,
      fieldSize: 12,
    },
  ],
  [UserOnboardingTabs.workDetails]: [
    {
      id: 1,
      fieldLabel: 'Employement Type',
      placeholderText: 'Select Employement Type',
      fieldType: FormFieldTypes.SingleSelect,
      isRequired: true,
      isDisabled: false,
      fieldRefName: FormFieldNames.EmploymentTypes,
    },
    {
      id: 2,
      fieldLabel: 'Employee ID',
      placeholderText: 'Auto generated ID',
      fieldType: FormFieldTypes.Text,
      isRequired: false,
      isDisabled: true,
      fieldRefName: FormFieldNames.EmployeeId,
    },
    {
      fieldLabel: 'Is Full-Time Employee',
      fieldType: FormFieldTypes.CheckBox,
      isRequired: false,
      fieldRefName: 'isFullTimeEmployee',
      fieldSize: 6.001,
    },
    {
      id: 99,
      fieldLabel: 'Date of Employment Conversion',
      placeholderText: 'dd/mm/yyyy',
      fieldType: FormFieldTypes.Date,
      isRequired: false,
      fieldRefName: FormFieldNames.EmploymentConvertedDate,
      fieldSize: 6.001,
      isHidden: true,
    },
    {
      id: 19,
      fieldLabel: 'Contract Start Date',
      placeholderText: 'dd/mm/yyyy',
      fieldType: FormFieldTypes.Date,
      isRequired: false,
      isDisabled: false,
      fieldRefName: FormFieldNames.ContractStartDate,
      isHidden: true,
    },
    {
      id: 20,
      fieldLabel: 'Contract End Date',
      placeholderText: 'dd/mm/yyyy',
      fieldType: FormFieldTypes.Date,
      isRequired: false,
      isDisabled: false,
      fieldRefName: FormFieldNames.ContractEndDate,
      isHidden: true,
    },
    {
      id: 3,
      fieldLabel: 'Employment Status',
      placeholderText: 'Select Status',
      fieldType: FormFieldTypes.SingleSelect,
      isRequired: true,
      fieldRefName: FormFieldNames.hrStatus,
    },
    {
      id: 4,
      fieldLabel: 'Role',
      placeholderText: 'Select Roles',
      fieldType: FormFieldTypes.MutliSelect,
      dataType: 'multiSelect',
      isRequired: true,
      fieldRefName: FormFieldNames.Roles,
    },
    {
      id: 5,
      fieldLabel: 'Designation',
      placeholderText: 'Select Designation',
      fieldType: FormFieldTypes.SingleSelect,
      isRequired: true,
      fieldRefName: FormFieldNames.Designation,
    },
    {
      id: 6,
      fieldLabel: 'Official Email ID',
      placeholderText: 'eg: info@company.com',
      fieldType: FormFieldTypes.Text,
      dataType: FormFieldTypes.Email,
      isRequired: true,
      fieldRefName: FormFieldNames.OfficialEmail,
    },
    {
      id: 7,
      fieldLabel: 'Cost Centre',
      placeholderText: 'Select Cost Centre',
      fieldType: FormFieldTypes.SingleSelect,
      isRequired: true,
      fieldRefName: FormFieldNames.CostCentre,
    },
    {
      id: 8,
      fieldLabel: 'Divisions',
      placeholderText: 'Select Divisions',
      fieldType: FormFieldTypes.SingleSelect,
      isRequired: true,
      isDisabled: false,
      fieldRefName: FormFieldNames.OrgDivisions,
    },
    {
      id: 11,
      fieldLabel: 'Job Function',
      placeholderText: 'Select job function',
      fieldType: FormFieldTypes.SingleSelect,
      isRequired: true,
      isDisabled: false,
      fieldRefName: FormFieldNames.Function,
      copyTo: FormFieldNames.ProbationConfirmation,
    },
    {
      id: 12,
      fieldLabel: 'Finance Category',
      placeholderText: 'Select Finance Category',
      fieldType: FormFieldTypes.SingleSelect,
      isRequired: true,
      isDisabled: false,
      fieldRefName: FormFieldNames.Category,
    },
    {
      id: 9,
      fieldLabel: 'Date of Joining',
      placeholderText: 'dd/mm/yyyy',
      fieldType: FormFieldTypes.Date,
      isRequired: true,
      fieldRefName: FormFieldNames.DateOfJoining,
      copyTo: FormFieldNames.ProbationConfirmation,
      subitem: {
        id: 2,
        fieldLabel: 'Years of Service',
        placeholderText: '0',
        fieldType: FormFieldTypes.Text,
        fieldRefName: 'yos',
        isRequired: false,
        isDisabled: true,
        textTransform: 'none',
        calc: (val) => getYearFromCurrentDate(val),
      },
    },
    {
      id: 10,
      fieldLabel: 'Probation Confirmation date',
      placeholderText: 'dd/mm/yyyy',
      fieldType: FormFieldTypes.Date,
      isRequired: false,
      fieldRefName: FormFieldNames.ProbationConfirmation,
    },
    {
      id: 13,
      fieldLabel: 'Department',
      placeholderText: 'Select Department',
      fieldType: FormFieldTypes.SingleSelect,
      isRequired: true,
      fieldRefName: FormFieldNames.Departments,
    },
    {
      id: 14,
      fieldLabel: 'Secondary Department',
      placeholderText: 'Select Secondary Departments',
      fieldType: FormFieldTypes.MutliSelect,
      dataType: 'multiSelect',
      isRequired: false,
      isDisabled: false,
      fieldRefName: FormFieldNames.SubDepartments,
    },
    {
      id: 15,
      fieldLabel: 'Location',
      placeholderText: 'Select Location',
      fieldType: FormFieldTypes.SingleSelect,
      isRequired: true,
      fieldRefName: FormFieldNames.Locations,
      fieldSize: 6,
      dataType: FormFieldTypes.Text,
    },
    {
      id: 18,
      fieldLabel: 'Assign Tag',
      placeholderText: 'Select Multiple Tags',
      fieldType: FormFieldTypes.MutliSelect,
      dataType: 'multiSelect',
      isRequired: false,
      fieldRefName: FormFieldNames.AssignTag,
      fieldSize: 6,
    },
    {
      id: 16,
      fieldLabel: 'Reporting To',
      placeholderText: 'Select Reporting Manager',
      fieldType: FormFieldTypes.SingleSelect,
      isRequired: false,
      fieldRefName: FormFieldNames.ReportingTo,
    },
    {
      id: 17,
      fieldLabel: 'Counter-Sign Officer',
      placeholderText: 'Select Option',
      fieldType: FormFieldTypes.SingleSelect,
      isRequired: false,
      fieldRefName: FormFieldNames.SecondaryReportingTo,
    },
  ],
  [UserOnboardingTabs.documents]: [
    {
      id: 1,
      fieldLabel: 'National Registration Identification Card',
      placeholderText: 'Upload in PNG, JPEG or PDF format',
      fieldType: FormFieldTypes.File,
      isRequired: false,
      fieldRefName: 'nationalIdentityProof',
    },
    {
      id: 2,
      fieldLabel: 'Foreign Identification Number',
      placeholderText: 'Upload in PNG, JPEG or PDF format',
      fieldType: FormFieldTypes.File,
      isRequired: false,
      fieldRefName: 'foreignIdentityProof',
    },
    {
      id: 3,
      fieldLabel: 'Passport',
      placeholderText: 'Upload in PNG, JPEG or PDF format',
      fieldType: FormFieldTypes.File,
      isRequired: false,
      fieldRefName: 'passport',
    },
    {
      id: 4,
      fieldLabel: 'Driving Licence',
      placeholderText: 'Upload in PNG, JPEG or PDF format',
      fieldType: FormFieldTypes.File,
      isRequired: false,
      fieldRefName: 'drivingLicence',
    },
  ],
  [UserOnboardingTabs.educationHistory]: [
    {
      id: 3,
      fieldLabel: 'Program Start Date',
      placeholderText: 'dd/mm/yyyy',
      fieldType: FormFieldTypes.Date,
      isRequired: false,
      fieldRefName: 'eduStartDate',
    },
    {
      id: 4,
      fieldLabel: 'Program End Date',
      placeholderText: 'dd/mm/yyyy',
      fieldType: FormFieldTypes.Date,
      isRequired: false,
      fieldRefName: 'eduEndDate',
    },
    {
      id: 1,
      fieldLabel: 'Program Name',
      placeholderText: 'Enter program/degree name',
      fieldType: FormFieldTypes.Text,
      isRequired: false,
      fieldRefName: 'degree',
    },
    {
      id: 2,
      fieldLabel: 'Institute Name',
      placeholderText: 'Enter institute/college/university name',
      fieldType: FormFieldTypes.Text,
      isRequired: false,
      fieldRefName: 'institute',
    },
    {
      id: 5,
      fieldLabel: 'Location',
      placeholderText: 'Enter location/place name',
      fieldType: FormFieldTypes.Text,
      isRequired: false,
      fieldRefName: 'eduLocation',
      fieldSize: 6,
    },
  ],
  [UserOnboardingTabs.employeeHistory]: [
    {
      id: 1,
      fieldLabel: 'Job Start Date',
      placeholderText: 'dd/mm/yyyy',
      fieldType: FormFieldTypes.Date,
      isRequired: true,
      fieldRefName: 'empStartDate',
    },
    {
      id: 2,
      fieldLabel: 'Job End Date',
      placeholderText: 'dd/mm/yyyy',
      fieldType: FormFieldTypes.Date,
      isRequired: true,
      fieldRefName: 'empEndDate',
    },
    {
      id: 3,
      fieldLabel: 'Designation Name',
      placeholderText: 'Enter position/role/designation name',
      fieldType: FormFieldTypes.Text,
      isRequired: false,
      fieldRefName: 'empDesignation',
    },
    {
      id: 4,
      fieldLabel: 'Company Name',
      placeholderText: 'Enter company name',
      fieldType: FormFieldTypes.Text,
      isRequired: false,
      fieldRefName: 'empCompany',
    },
    {
      id: 5,
      fieldLabel: 'Location',
      placeholderText: 'Enter location/place name',
      fieldType: FormFieldTypes.Text,
      isRequired: false,
      fieldRefName: 'empLocation',
      fieldSize: 6,
    },
  ],
  [UserOnboardingTabs.payrollAssignment]: [
    {
      id: 1,
      fieldLabel: 'Assign Pay Structure',
      placeholderText: 'Select Option',
      fieldType: FormFieldTypes.SingleSelect,
      isRequired: true,
      fieldRefName: FormFieldNames.Assignpaystr,
      fieldSize: 6,
      dataType: FormFieldTypes.Text,
    },
    {
      id: 2,
      fieldLabel: 'Effective Date',
      placeholderText: 'dd/mm/yyyy',
      fieldType: FormFieldTypes.Date,
      isRequired: false,
      fieldRefName: 'effectiveDate',
      fieldSize: 6,
      isReadOnly: true,
    },
  ],
};

export const bankInfoFields = [{
  id: 1,
  fieldLabel: 'Bank Account Number',
  placeholderText: 'Type Here',
  fieldType: FormFieldTypes.Text,
  isRequired: false,
  fieldRefName: 'bankAccountNumber',
},
{
  id: 2,
  fieldLabel: 'Bank Swift Code',
  placeholderText: 'Type Here',
  fieldType: FormFieldTypes.Text,
  isRequired: false,
  fieldRefName: 'bankSwiftCode',
},
{
  id: 3,
  fieldLabel: 'Bank Name',
  placeholderText: 'Type Here',
  fieldType: FormFieldTypes.Text,
  isRequired: false,
  fieldRefName: 'bankName',
}];

export const getMandatoryFields = (fields) => {
  return fields?.filter((item) => item.isRequired);
};

export const getHiddenMandatoryFields = (fields) => {
  return fields?.filter((item) => item.isRequired && !item.isHidden);
};

export const getValidationErrors = (formFields = {}, requiredFields) => {
  let errors = {};
  requiredFields.forEach((item) => {
    const { fieldRefName, fieldLabel, fieldType, dataType } = item;
    if (formFields[fieldRefName] === null || formFields[fieldRefName] === undefined || formFields[fieldRefName] === '') {
      errors = { ...errors, [fieldRefName]: `${fieldLabel} can't be empty` };
    } else if (
      fieldType === FormFieldTypes.MutliSelect &&
      isEmpty(formFields[fieldRefName])
    ) {
      errors = {
        ...errors,
        [fieldRefName]: 'Please select at least one value',
      };
    } else if (
      dataType === FormFieldTypes.Email &&
      !validateEmail(formFields[fieldRefName])
    ) {
      errors = { ...errors, [fieldRefName]: 'Invalid email format' };
    } else if (!isNotEmptyString(formFields[fieldRefName])) {
      errors = { ...errors, [fieldRefName]: `${fieldLabel} can't be empty` };
    }
  });
  return errors;
};

export const userOnboardingBreadCrumbMenu = [
  {
    tabName: 'Onboarding',
  },
  {
    tabName: 'User',
  },
];

export const getFormBody = (formInfo = {}) => {
  let newFormInfo = formInfo;
  Object.keys(formInfo).forEach((key) => {
    if (formInfo[key]?.code) {
      newFormInfo = {
        ...newFormInfo,
        [key]: formInfo[key].code,
      };
    } else if (
      formInfo[key]?.id ||
      formInfo[key]?.id == 0 ||
      formInfo[key]?.id === null
    ) {
      newFormInfo = {
        ...newFormInfo,
        [key]: formInfo[key].id,
      };
    } else if (
      Array.isArray(formInfo[key]) &&
      key !== FormFieldNames.Documents &&
      key !== KRAFormFieldNames.TargetPoint &&
      key !== ConditionFormFieldsName.Value &&
      !(
        moment(formInfo[key][0], 'YYYY-MM-DD', true).isValid() ||
        moment(formInfo[key][1], 'YYYY-MM-DD', true).isValid()
      )
    ) {
      newFormInfo = {
        ...newFormInfo,
        [key]: getListOfIds(formInfo[key]),
      };
    } else if (
      moment(formInfo[key], 'DD/MM/YYYY', true).isValid() ||
      moment(formInfo[key], 'DD-MM-YYYY', true).isValid()
    ) {
      newFormInfo = {
        ...newFormInfo,
        [key]: moment(formInfo[key], 'DD/MM/YYYY').format().split('T')[0],
      };
    } else if (formInfo[key]?.interviewStatusCode) {
      newFormInfo = {
        ...newFormInfo,
        [key]: formInfo[key].interviewStatusCode,
      };
    } else if (formInfo[key]?.salaryTypeCode) {
      newFormInfo = {
        ...newFormInfo,
        [key]: formInfo[key].salaryTypeCode,
      };
    }
  });

  return newFormInfo;
};

export const allUserHeadCells = (isHrOD)=> {
 return ([
  {
    id: 'name',
    label: 'Employee name',
  },
  {
    id: FormFieldNames.EmployeeId,
    label: 'Employee ID',
  },
  {
    id: FormFieldNames.DateOfJoining,
    label: 'Date of Joining',
    hide: isHrOD
  },
  {
    id: FormFieldNames.UpdatedAt,
    label: 'Activity date',
    hide: isHrOD
  },
  {
    id: FormFieldNames.Roles,
    label: 'Role',
    hide: isHrOD
  },
  {
    id: FormFieldNames.Departments,
    label: 'Departments',
  },
  // {
  //   id: 'documents',
  //   label: 'Documents',
  // },
  {
    id: 'status',
    label: 'Status',
    hide: isHrOD
  },
  {
    id: 'createdAt',
    label: 'Added On',
    dataType: 'date',
    dateFormat: 'YYYY/MM/DD',
    hide: isHrOD
  },
]);};

export const documentsHeadCells = [
  {
    id: 'name',
    label: 'Document Name',
  },
  {
    id: 'upload',
    label: 'Upload Document',
  },
  {
    id: 'uploadedOn',
    label: 'Uploaded On',
  },
  {
    id: 'download',
    label: 'Inspect Document',
  },
];

const getListOfIds = (list) => list.map((item) => item?.id);

export const UserStatus = {
  Inactive: 99,
  Draft: 1,
  Published: 2,
  Deactivate: 3,
  Activate: 4,
  Resign: 10,
};

export const CfgApprovalAction = {
  SEND_BACK: 8,
  RESIGNED: 9,
  REJECTED: 4,
  RE_SUBMIT: 2,
  PENDING: 1,
};

export const EmpType = {
  Contract: 3,
};

export const myJourneyFormViewFields = [
  {
    id: 1,
    fieldLabel: 'Employement Type',
    placeholderText: 'Select Employement Type',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: true,
    isDisabled: false,
    fieldRefName: FormFieldNames.EmploymentTypes,
  },
  {
    id: 3,
    fieldLabel: 'Employment Status',
    placeholderText: 'Select Status',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: true,
    fieldRefName: FormFieldNames.hrStatus,
  },
  {
    id: 9,
    fieldLabel: 'Date of Joining',
    placeholderText: 'dd/mm/yyyy',
    fieldType: FormFieldTypes.Date,
    isRequired: true,
    fieldRefName: FormFieldNames.DateOfJoining,
    copyTo: FormFieldNames.ProbationConfirmation,
    subitem: {
      id: 2,
      fieldLabel: 'Years of Service',
      placeholderText: '0',
      fieldType: FormFieldTypes.Text,
      fieldRefName: 'yos',
      isRequired: false,
      isDisabled: true,
      textTransform: 'none',
      calc: (val) => getYearFromCurrentDate(val),
    },
  },
  {
    id: 10,
    fieldLabel: 'Probation Confirmation date',
    placeholderText: 'dd/mm/yyyy',
    fieldType: FormFieldTypes.Date,
    isRequired: false,
    fieldRefName: FormFieldNames.ProbationConfirmation,
  },
  {
    id: 4,
    fieldLabel: 'Role',
    placeholderText: 'Select Roles',
    fieldType: FormFieldTypes.MutliSelect,
    dataType: 'multiSelect',
    isRequired: true,
    fieldRefName: FormFieldNames.Roles,
  },
  {
    id: 5,
    fieldLabel: 'Designation',
    placeholderText: 'Select Designation',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: true,
    fieldRefName: FormFieldNames.Designation,
  },
  {
    id: 6,
    fieldLabel: 'Official Email ID',
    placeholderText: 'eg: info@company.com',
    fieldType: FormFieldTypes.Text,
    dataType: FormFieldTypes.Email,
    isRequired: true,
    fieldRefName: FormFieldNames.OfficialEmail,
  },
  {
    id: 16,
    fieldLabel: 'Reporting To',
    placeholderText: 'Select Reporting Manager',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: false,
    fieldRefName: FormFieldNames.ReportingTo,
  },
  {
    id: 7,
    fieldLabel: 'Cost Centre',
    placeholderText: 'Select Cost Centre',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: true,
    fieldRefName: FormFieldNames.CostCentre,
  },
  {
    id: 8,
    fieldLabel: 'Divisions',
    placeholderText: 'Select Divisions',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: true,
    isDisabled: false,
    fieldRefName: FormFieldNames.OrgDivisions,
  },
  {
    id: 11,
    fieldLabel: 'Job Function',
    placeholderText: 'Select job function',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: true,
    isDisabled: false,
    fieldRefName: FormFieldNames.Function,
    copyTo: FormFieldNames.ProbationConfirmation,
  },
  {
    id: 12,
    fieldLabel: 'Finance Category',
    placeholderText: 'Select Finance Category',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: true,
    isDisabled: false,
    fieldRefName: FormFieldNames.Category,
  },
  {
    id: 13,
    fieldLabel: 'Department',
    placeholderText: 'Select Department',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: true,
    fieldRefName: FormFieldNames.Departments,
  },
  {
    id: 14,
    fieldLabel: 'Sub Department',
    placeholderText: 'Select Department',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: false,
    isDisabled: false,
    fieldRefName: FormFieldNames.SubDepartments,
  },
  {
    id: 15,
    fieldLabel: 'Location',
    placeholderText: 'Select Location',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: true,
    fieldRefName: FormFieldNames.Locations,
    fieldSize: 12,
    dataType: FormFieldTypes.Text,
  },
];

export const totalForComponents = (componentValues) => {
  if (!componentValues || componentValues.length === 0) {
    return 0;
  }
  return componentValues.reduce((accumulator, formInfo) => {
    if (formInfo.inputSourceCode === ComponentInputType.Onetime) {
      return accumulator + Number(formInfo.value);
    }
    return accumulator;
  }, 0);
};

export const totalForAllComponents = (componentValues) => {
  if (!componentValues || componentValues.length === 0) {
    return 0;
  }
  return componentValues.reduce((accumulator, formInfo) => {
    return accumulator + Number(formInfo.value);
  }, 0);
};