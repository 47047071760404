import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import { Box, Typography, Link, IconButton } from '@mui/material';
import CardView from '../../../atoms/DataList/CardView';
import ListView from '../../../atoms/DataList/ListView';
import DataConfigure from './DataConfigure';
import { FormHeader } from '../common/formHeader';
import { getDepartmentInfo, postDepartment, deleteDepartment } from '../../../redux/onboarding/action';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { isNotEmptyString } from './utils/onboarding.util';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { departmentsListHeader } from './utils/onboarding.util';
import ModalDialog from '../../../atoms/Modal/ModalDialog';
import { doesNameAlreadyExists, getHelperTextOnInputInvalid, scrollToNewRow } from './utils/onboarding.util';
import { APIRequestStatus, LoadingStatus } from '../../../constants/common';
import CircularLoader from '../../../atoms/CircularLoader/circular-loader';
import { departments as departmentConst } from './constants/onboarding.constants';
import TryAgain from '../../molecules/ErrorState/error.component';



export default function Departments() {

  const [showConfigure, setShowConfigure] = useState(false);
  const [departmentName, setDepartmentName] = useState('');
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [managerName, setManagerName] = useState('');
  const [supervisorName, setSupervisorName] = useState('');
  const [managerId, setManagerId] = useState('');
  const [supervisorId, setSupervisorId] = useState('');
  const [configureIndex, setConfigureIndex] = useState(-1);
  const [errors, setErrors] = useState();
  const [showModal, setshowModal] = useState(false);
  const [context, setContext] = useState();
  const [allowAdd, setAllowAdd] = useState(true);
  const [tableRef, setTableRef] = useState();

  const onClickConfigure = (id, name, managerName, supervisorName, managerId, supervisorId, index) => {
    setShowConfigure(true);
    setId(id);
    setName(name);
    setManagerName(managerName);
    setSupervisorName(supervisorName);
    setConfigureIndex(index);
    setManagerId(managerId);
    setSupervisorId(supervisorId);
  };
  const dispatch = useDispatch();


  const { departments, permissions, requestProcessing: { [APIRequestStatus.POST_DEPARTMENT_SAVE]: postDeptLoadingStatus,
    [APIRequestStatus.GET_DEPARTMENT_INFO]: getDepartmentsLoading
  } } = useSelector((state) => state.onBoarding);
  const { currentUserRoleId } = useSelector((state) => state.main);
  useEffect(() => {
    if (isEmpty(departments))
      dispatch(getDepartmentInfo(currentUserRoleId));
  }, []);

  useEffect(() => {
    if (postDeptLoadingStatus === LoadingStatus.Success) {
      scrollToNewRow(tableRef);
    }
  }, [postDeptLoadingStatus]);

  const handleAddDepartment = () => {
    if (isNotEmptyString(departmentName)) {
      dispatch(postDepartment(currentUserRoleId,departmentName.trim()));
      setDepartmentName('');
    }
    else {
      setAllowAdd(false);
      setErrors(errors);
    }
  };

  const handleDelete = (id, index) => {
    setshowModal(true);
    setContext('delete');
    setId(id);
    setConfigureIndex(index);
  };

  const onClickAddIcon = () => {
    setshowModal(true);
    setContext('add');
  };
  const onChange = (event) => {
    const name = event.target.value;
    if (!doesNameAlreadyExists(departments, name) && (isNotEmptyString(name))) {
      setAllowAdd(true);
    }
    else {
      setAllowAdd(false);
    }
    setDepartmentName(event.target.value);
  };

  const onModalClose = () => {
    setshowModal(false);
    setAllowAdd(true);
    setDepartmentName('');
  };

  const renderLayout = () => {
    if (showConfigure) {
      return (
        <Box>
          <DataConfigure
            name={name}
            id={id}
            managerName={managerName}
            supervisorName={supervisorName}
            index={configureIndex}
            departmentView={() => setShowConfigure(false)}
            managerId={managerId}
            supervisorId={supervisorId}
          />
        </Box>
      );
    }
    return (
      <Box>
        <FormHeader title='Departments' />
        {departments?.length < 9 ?
          <Grid container rowSpacing={3} columnSpacing={10}>
            {departments?.map((item, index) => {
              const { id, name, managerName, supervisorName, supervisorId, managerId, departmentMembers } = item;
              return (
                <Grid key={id} item xs={6} md={4}>
                  <CardView
                    title={name}
                    buttonText='Configure'
                    onClick={() => onClickConfigure(id, name, managerName, supervisorName, managerId, supervisorId, index)}
                    count={departmentMembers?.length || 0}
                    countBox={true}
                    onClickDelete={() => handleDelete(id, index)}
                    primaryButtonDisabled={!permissions[departmentConst]?.allowEdit}
                    deleteButtonDisabled={!permissions[departmentConst]?.allowEdit}
                  />
                </Grid>
              );
            })}
            {permissions[departmentConst]?.allowAdd && (
              <Grid item xs={6} md={4}>
                <CardView
                  buttonText='Add Department'
                  onClick={() => handleAddDepartment()}
                  count={0}
                  editable={true}
                  onChange={onChange}
                  value={departmentName}
                  placeholder="Add Department"
                  helperText={getHelperTextOnInputInvalid(allowAdd)}
                  primaryButtonDisabled={!allowAdd}
                  countBox={true}
                />
              </Grid>
            )}
          </Grid>
          : <ListView
            headCells={departmentsListHeader}
            rowsData={departmentsList}
            onAddClick={() => onClickAddIcon()}
            showSerialNo={true}
            setTableRef={setTableRef}
            showAddIcon={permissions[departmentConst]?.allowAdd}
            addDisabled={!permissions[departmentConst]?.allowAdd}
          />
        }
      </Box>
    );
  };
  const departmentsList = departments?.map((dept, index) => {
    const { id, name, managerName, supervisorName, supervisorId, managerId, departmentMembers, departmentCode } = dept;
    if (!permissions[departmentConst]?.allowEdit) {
      return {
        name: name || '',
        departmentCode: departmentCode || '',
        managerName: managerName || '',
        supervisorName: supervisorName || '',
      };
    }
    return {
      name: name || '',
      departmentCode: departmentCode || '',
      managerName: managerName || '',
      supervisorName: supervisorName || '',
      actions: <>
        <IconButton onClick={() => onClickConfigure(id, name, managerName, supervisorName, managerId, supervisorId, index)}
          disabled={!permissions[departmentConst]?.allowEdit}>
          <EditOutlinedIcon /></IconButton>
        <IconButton onClick={() => handleDelete(id, index)}
          disabled={!permissions[departmentConst]?.allowEdit}>
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      </>
    };
  });

  const modalMap = {
    delete: {
      title: 'Delete Department',
      description: 'Are you sure you want to Delete Department?',
      primaryAction: 'Delete',
      onClickConfirm: () => dispatch(deleteDepartment(currentUserRoleId, id, configureIndex)),
      severity: 'error'
    },
    add: {
      title: 'Add Department',
      componentProps: {
        onInputChange: onChange,
        inputValue: departmentName,
        inputPlaceHolder: 'Add Department',
        helperText: getHelperTextOnInputInvalid(allowAdd),
        countBox: true
      },
      primaryAction: 'Add',
      onClickConfirm: () => handleAddDepartment(),
      severity: 'success'
    }
  };

  if (getDepartmentsLoading === LoadingStatus.Failure) {
    return <TryAgain />;
  } else {
    return (
      <Box>
        <CircularLoader show={getDepartmentsLoading === LoadingStatus.Loading} />
        {renderLayout()}
        <ModalDialog
          severity={modalMap[context]?.severity}
          title={modalMap[context]?.title}
          description={modalMap[context]?.description}
          primaryAction={modalMap[context]?.primaryAction}
          secondaryAction="Cancel"
          showModal={showModal}
          onClickClose={() => onModalClose()}
          onClickConfirm={modalMap[context]?.onClickConfirm}
          primaryButtonDisabled={!allowAdd}
          component={context !== 'delete'}
          componentProps={modalMap[context]?.componentProps}
        />
      </Box>
    );
  }
}

